<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>Novo item de Menu</h3>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              :to="{ name: 'configuration-company' }"
              exact
            >
              Voltar
            </v-btn>
          </v-card-title>
          <v-row>
            <v-card-text>
              <v-col cols="12">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-text-field
                    v-model="menu_item.text"
                    label="Nome"
                    outlined
                    dense
                    required
                    :rules="textRule"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="menu_item.route"
                    label="Rota"
                    outlined
                    dense
                    required
                    :rules="routeRule"
                  >
                  </v-text-field>
                  <v-autocomplete
                    v-model="menu_item.group"
                    :items="user_group.data"
                    outlined
                    chips
                    label="Grupos"
                    item-text="text"
                    item-value="id_join"
                    multiple
                    dense
                    required
                    :rules="groupRule"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click:close="remove(data.item)"
                        dense
                      >
                        {{ data.item.text }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <v-switch
                    v-model="menu_item.active"
                    :label="menu_item.active ? 'Ativo' : 'Inativo'"
                    inset
                  ></v-switch>
                  <v-card outlined>
                    <v-card-text class="subtitle-1">
                      Selecione um icone
                    </v-card-text>
                    <div class="pa-4">
                      <v-chip-group
                        v-model="menu_item.icon"
                        active-class="primary"
                        column
                      >
                        <v-chip
                          v-for="icon in icons"
                          :key="icon.name"
                          :value="icon.name"
                        >
                          <v-icon>{{ icon.name }}</v-icon>
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn
                  color="primary"
                  @click="saveMenuItem"
                  :loading="creating"
                  :disabled="creating"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      menu_item: {
        text: "",
        route: "/",
        group: "",
        icon: "",
        active: false
      },
      creating: false,
      valid: true,
      textRule: [v => !!v || "O campo nome é obrigarório"],
      routeRule: [v => !!v || "O campo rota é obrigarório"],
      groupRule: [v => !!v || "O campo grupos é obrigarório"]
    };
  },
  async mounted() {
    await this.loadUserGroup().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loadingMenu = false;
  },
  computed: {
    ...mapState("icon", ["icons"]),
    ...mapState("menu", ["menu_save"]),
    ...mapState("userGroup", ["user_group"])
  },
  methods: {
    ...mapActions("menu", ["createMenuItem"]),
    ...mapActions("userGroup", ["loadUserGroup"]),
    remove(item) {
      const index = this.menu_item.group.indexOf(item.id_join);
      if (index >= 0) this.menu_item.group.splice(index, 1);
    },
    async saveMenuItem() {
      if (this.$refs.form.validate()) {
        this.creating = true;
        const params = this.menu_item;
        await this.createMenuItem(params).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        if (this.menu_save.success) {
          this.$swal({
            icon: "success",
            title: this.menu_save.message,
            showCancelButton: true,
            confirmButtonText: "Continuar cadastrando",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: true
          }).then(result => {
            if (!result.value) {
              this.$router.push({
                name: "configuration-company"
              });
            } else {
              this.menu_item = {
                text: "",
                route: "",
                group: "",
                icon: "",
                active: false
              };
              this.$refs.form.resetValidation();
            }
          });
        } else {
          await this.$swal("Oops ...", this.menu_save.message, "warning");
        }
        this.creating = false;
      }
    }
  }
};
</script>
